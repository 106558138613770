import { build } from 'nuxt';
import type { GPLocaleObject } from '~/locales/types';

export interface StrapiLocalizedSlug {
  locale: string;

  // Example: gameserver/minecraft-server-hosting
  slug: string;
}

interface I18nLocalizedSlug {
  slug: string;
}

interface I18nSlugPayload {
  [key: string]: I18nLocalizedSlug | Record<string, unknown> | boolean;
}

export interface UseI18nLocalizedSlugsOptions {
  setHead?: boolean;
  paramName?: string;
}

const getOptions = (options: UseI18nLocalizedSlugsOptions) => {
  return {
    setHead: true,
    paramName: 'slug',
    ...options,
  };
};

/**
 * This composables sets the localized slugs for the current page.
 *
 * Use this composable in places, where you do fetches from GQL and have localized content with alternate languages.
 *
 * @returns setI18nSlugs
 */
export const setI18nLocalizedSlugs = (
  localizedPaths: MaybeRef<StrapiLocalizedSlug[]>,
  options: {
    setHead?: boolean;
    paramName?: string;
  } = {
    setHead: true,
    paramName: 'slug',
  },
) => {
  const { $i18n } = useNuxtApp();
  const locales = computed(() => $i18n.locales.value);

  const { paramName: paramNameOpt } = getOptions(options);

  /**
   * This is the mapping reflects all locales and their corresponding locale object with the localized slugs.
   *
   * For example:
   *   * slugsRef contains slugs for `en` and `de`
   *   * locales contains `en`, `de`, `fr`
   *   * the mapping will contain the properties for `en`, `de`, `en-US`, `de-DE`
   */
  const localesMapping = computed<{
    [key: string]: GPLocaleObject;
  }>(() => {
    const mapping = {} as Record<string, GPLocaleObject>;
    const localizedPathsRaw = unref(localizedPaths);

    if (localizedPathsRaw == null) return mapping;

    for (const locale of locales.value) {
      // we need only the locales, wich are in in the localized slugs array
      if (
        localizedPathsRaw.some(
          (localizedEntry) => localizedEntry.locale === locale.code,
        )
      ) {
        mapping[locale.code] = locale;
        mapping[locale.iso] = locale;
      }
    }

    return mapping;
  });

  const switchLocalePath = useSwitchLocalePath();
  const config = useRuntimeConfig();
  const buildUrl = (locale: string, slug: string, pathOnly?: boolean) => {
    const base = pathOnly ? '' : config.public.websiteUrl;

    if (slug == null || slug.length === 0) {
      return `${base}${switchLocalePath(locale)}`;
    }

    if (slug.endsWith('/index')) {
      return `${base}${switchLocalePath(locale)}`;
    }

    return `${base}/${locale}/${slug}`;
  };

  const findLocalizedPath = (locale: string) => {
    const localizedPathsRaw = unref(localizedPaths);
    if (localizedPathsRaw == null) return null;

    return unref(localizedPaths).find((l) => l.locale === locale)?.[
      paramNameOpt
    ] as string;
  };

  const localizedState = useState('localized-slugs');
  localizedState.value = locales.value
    .map((locale) => {
      const localizedPath = findLocalizedPath(locale.code);

      if (localizedPath != null) {
        return {
          locale: locale.code,
          path: buildUrl(locale.code, localizedPath, true),
        };
      }

      return null;
    })
    .filter((l) => l != null);

  const reducedLinks = computed(() => {
    const links = [] as Array<{
      rel: 'alternate' | 'canonical';
      href: string;
      hreflang?: string;
      key?: string;
    }>;

    if (
      localesMapping.value == null ||
      Object.keys(localesMapping.value).length == 0
    ) {
      return links;
    }

    for (const [localeKey, localeObject] of Object.entries(
      localesMapping.value,
    )) {
      const localizedPath = findLocalizedPath(localeObject.code);

      if (localizedPath != null) {
        links.push({
          rel: 'alternate',
          href: buildUrl(localeObject.code, localizedPath),
          hreflang: localeKey,
          key: `alternate-${localeKey}`,
        });
      }
    }

    // current locale
    const currentLocale = localesMapping.value[$i18n.locale.value];
    if (currentLocale != null) {
      const localizedPath = findLocalizedPath($i18n.locale.value);

      links.push({
        rel: 'canonical',
        href: buildUrl(currentLocale.code, localizedPath),
        key: 'canonical',
      });
    }

    // default locale
    const defaultLocale = localesMapping.value.en;
    if (defaultLocale != null) {
      const localizedPath = findLocalizedPath(defaultLocale.code);

      links.push({
        rel: 'alternate',
        href: buildUrl(defaultLocale.code, localizedPath),
        hreflang: 'x-default',
        key: 'alternate-x-default',
      });
    }

    return links;
  });

  const ogLocaleMeta = computed(() => {
    const metas = [];
    if (
      localesMapping.value == null ||
      Object.keys(localesMapping.value).length == 0
    ) {
      return metas;
    }

    if (localesMapping.value[$i18n.locale.value] != null) {
      metas.push({
        property: 'og:locale',
        content: localesMapping.value[$i18n.locale.value].iso.replace('-', '_'),
      });
    }

    return metas.concat(
      unref(localizedPaths)
        // first, we make sure, that the locale is in the mapping
        .filter(
          (localizedEntry) =>
            localesMapping.value[localizedEntry.locale] != null,
        )
        // then we map the localized slugs to the og:locale:alternate meta
        .map((localizedEntry) => {
          return {
            property: 'og:locale:alternate',
            content: localesMapping.value[localizedEntry.locale].iso.replace(
              '-',
              '_',
            ),
            key: `og:locale:alternate-${localizedEntry.locale}`,
          };
        }),
    );
  });

  useHead({
    link: () => reducedLinks.value,
    meta: () => ogLocaleMeta.value,
  });
};

export default setI18nLocalizedSlugs;
